import React, { useEffect, useState } from "react"
// import { capitalizedFirst } from "src/utils/capitalized-first-character"
// import { useTranslation } from "react-i18next"

export interface CountDownProps {
  tartGetDate: number
  handlePaymentFail: () => void
  isHiddenUI?: boolean
}
export default function CountDownView({
  tartGetDate,
  handlePaymentFail,
  isHiddenUI,
}: CountDownProps) {
  // const { t } = useTranslation()
  const [hours, setHours] = useState<number>(0)
  const [minutes, setMinutes] = useState<number>(0)
  const [seconds, setSeconds] = useState<number>(0)
  const targetTime = new Date(tartGetDate)

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date()
      const different = targetTime.getTime() - now.getTime()
      const h = Math.floor(
        (different % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      setHours(h)
      const m = Math.floor((different % (1000 * 60 * 60)) / (1000 * 60))
      setMinutes(m)
      const s = Math.floor((different % (1000 * 60)) / 1000)
      setSeconds(s)
      if (h <= 0 && m <= 0 && s <= m) {
        handlePaymentFail()
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [tartGetDate])

  if(isHiddenUI) return null
  return (
    <div className="flex flex-col items-end gap-1">
      {/* <span className="text-[14px] font-semibold leading-[20px] text-white ">
        {capitalizedFirst(t("shoppingPage.validity-period"))}
      </span> */}
      <div className="flex items-center gap-1">
        <div
          className={
            "flex items-center justify-center rounded-1 bg-orange-dark-50 p-4px px-1 text-16 font-semibold leading-6 text-blue-600 "
          }
        >
          {hours <= 9 ? <div>{`0${hours}`}</div> : <div>{hours}</div>}
        </div>
        <div className={"text-14 font-medium text-gray-600 text-white "}>:</div>
        <div
          className={
            "flex items-center justify-center rounded-1 bg-orange-dark-50 p-4px px-1 text-16 font-semibold leading-6 text-blue-600"
          }
        >
          {minutes <= 9 ? <div>{`0${minutes}`}</div> : <div>{minutes}</div>}
        </div>
        <div className={"text-14 font-medium text-gray-600 text-white"}>:</div>
        <div
          className={
            "flex items-center justify-center rounded-1 bg-orange-dark-50 p-4px px-1 text-16 font-semibold leading-6 text-blue-600"
          }
        >
          {seconds <= 9 ? <div>{`0${seconds}`}</div> : <div>{seconds}</div>}
        </div>
      </div>
    </div>
  )
}
