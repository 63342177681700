/* eslint-disable @typescript-eslint/no-explicit-any */
import { BloomFilter } from "soctrip-algorithm/dist/api.js"

export const handleConvertPrice = (price: number | bigint | undefined) => {
  const config = {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
  }
  if (price) {
    const formatted = new Intl.NumberFormat("en-US", config).format(price)
    return formatted
  } else {
    const formatted = new Intl.NumberFormat("en-US", config).format(0)
    return formatted
  }
}

export const handlePromiseAll = (promises: any[]) => {
  const wrappedPromises = promises.map((p) =>
    Promise.resolve(p).then(
      (val) => ({ status: "fulfilled", data: val }),
      (err) => ({ status: "rejected", reason: err })
    )
  )
  return Promise.all(wrappedPromises)
}

export const handlePromiseAllNoRejected = (promises: any[]) => {
  const wrappedPromises = promises.map((p) =>
    Promise.resolve(p).then((val) => ({ status: "fulfilled", data: val }))
  )
  return Promise.all(wrappedPromises)
}

export const handleCheckBloomFilter = (
  bloomData: string,
  itemCheck: string
) => {
  if (bloomData && itemCheck) {
    const bloomFilter = BloomFilter.load(bloomData)
    const exist = bloomFilter.has(itemCheck)
    if (exist) {
      return true
    } else {
      return false
    }
  } else return false
}

export const getThumbnailVideo = (idVideo: string) => {
  return `${process.env.REACT_APP_API_URL}/storage-streaming/files/thumbnail/${idVideo}.webp`
}
export const delay = (delayTime: number) => {
  return new Promise((resolve) => setTimeout(resolve, delayTime))
}
