declare const window: {
    soctripMobileMessageListeners?: Set<(m: MobileMessage)=>void>, 
    FlutterMobileChannel?: {postMessage: (m: string)=>void}
} & Window & typeof globalThis


/**
 * @deprecated We will use MobileRequestType instead in the future.
 */
export type MobileResponseMessageType = "setAccessToken"

/**
 * @deprecated We will use MobileRequestType instead in the future.
 */
export type MobileRequestMessageType = "getAccessToken"


export type MobileRequestType = "language" | "accessToken"

export type MobileMessage = {
  type: MobileResponseMessageType | MobileRequestType,
  data?: string,
  requestId?: string,
}

export function addMobileMessageListener(listener: (m: MobileMessage) => void) {
  window.soctripMobileMessageListeners = window.soctripMobileMessageListeners ?? new Set()
  window.soctripMobileMessageListeners.add(listener)
}

export function removeMobileMessageListener(listener: (m: MobileMessage) => void) {
  window.soctripMobileMessageListeners?.delete(listener)
}


export function postMessageToMobile(type: MobileRequestMessageType | MobileRequestType, requestId?: string, data?: string) {
  if (!window.FlutterMobileChannel) {
    return
  }
  window.FlutterMobileChannel.postMessage(JSON.stringify({
    type,
    requestId,
    data,
  }))
}

let requestId = 0
function getNextRequestId(): number {
  requestId++
  if (requestId > Number.MAX_SAFE_INTEGER) {
    requestId = Number.MIN_SAFE_INTEGER
  }
  return requestId
}

export function requestMobile(type: MobileRequestType, data?: string): Promise<MobileMessage> {
  const requestId = `${getNextRequestId()}`
  return new Promise((resolve) => {
    const listener = (m: MobileMessage) => {
      if (requestId !== m.requestId) return
      removeMobileMessageListener(listener)
      resolve(m)
    }
    addMobileMessageListener(listener)
    postMessageToMobile(type, requestId, data)
  })
}