import React, { useEffect, useState } from "react"
import completeIcon from "src/assets/images/shopping-complete-order-icon.svg"

export default function PaymentComplete() {
  const [callbackUrl, setCallbackUrl] = useState<string>("")
  useEffect(() => {
    const url = localStorage.getItem("callbackUrl") || ""
    setCallbackUrl(url)
  }, [])

  const gotoCallbackUrl = () => {
    if (callbackUrl) {
      localStorage.removeItem("callbackUrl")
      setTimeout(() => {
        window.open(callbackUrl, "_self")
      }, 300)
    }
  }
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center rounded-3 bg-white py-2">
      <div className="flex h-full w-full flex-col items-center justify-center">
        <img
          onClick={gotoCallbackUrl}
          src={completeIcon}
          alt="empty image"
          className="cursor-pointer object-cover object-center"
        />
        <div className="text-16 font-medium text-gray-500">
          {"The transaction has been completed successfully!"}
        </div>
      </div>
    </div>
  )
}
