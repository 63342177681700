export const STATE_VIEW = {
  INIT: "INIT",
  GET_SESSION: "GET_SESSION",
  RENDER: "RENDER",
}
export interface PaymentDetailType {
  failCallback: string
  expiredTime: number
  redirectUrl: string
}
