import React, { useMemo } from "react"
import { getWhiteLogoApp } from "src/utils/app-logo"
import expiredTimeLogo from "src/assets/images/expited-time-logo.svg"
import { useTranslation } from "react-i18next"

export const LoadingView = () => {
  return (
    <div className="z-2 absolute flex h-full w-full items-center justify-center bg-white opacity-50">
      <div className="pi pi-spinner animate-spin text-[30px]" />
    </div>
  )
}
export interface ExpiredTimeViewType {
  homeUrl: string
}
export default function ExpiredTimeView(props: ExpiredTimeViewType) {
  const { homeUrl } = props
  const {t} = useTranslation()
  const handleLogoClick = () => {
    const homeUrl = process.env.REACT_APP_HOME_DOMAIN_URL
    window.open(homeUrl, "_self")
  }
  const backToHome = () => {
    const result = homeUrl.split(".com")[0] + ".com"
    if (homeUrl && result) window.open(result, "_self")
  }
  const getAppTitle = useMemo(() => {
    return process.env.REACT_APP_TITLE
  }, [])
  
  return (
    <div className="relative flex h-full w-full flex-col">
      <div className=" h-[52px] bg-[linear-gradient(99.24deg,#1879FF_12.47%,#0658CC_98.43%)] md:h-[72px]">
        <div className=" max-w-[1200px] mx-auto flex h-full items-center px-[24px] md:px-[0px]">
          <div
            className="flex cursor-pointer items-center"
            onClick={handleLogoClick}
          >
            <img
              alt="appLogo"
              className="h-[20px] w-[20px] md:h-[38px] md:w-[38px]"
              src={getWhiteLogoApp()}
            />
            <span className="ml-[10px] text-[20px] font-bold leading-[24px] text-white md:text-[32px] md:leading-[38px]">
              {getAppTitle}
            </span>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-1 flex-col items-center px-[24px] pt-[48px] md:px-[0px] md:pt-[160px]">
        <span className="text-[36px] font-semibold leading-[44px] text-blue-600">{`${getAppTitle} ${t("payment-page.payment-gateway")}`}</span>
        <div className="mt-[48px] flex flex-col items-center md:p-[40px]">
          <img
            className="h-[172px] w-[172px]"
            alt="expiredTimeIcon"
            src={expiredTimeLogo}
          />
          <span className="mt-[24px] text-center text-[18px] font-semibold leading-[28px] text-gray-900">
            {t("payment-page.session-expired")}
          </span>
          <button
            onClick={backToHome}
            className="mt-[32px] rounded-3 bg-blue-600 px-[20px] py-3 text-[14px] font-semibold leading-[20px] text-white hover:bg-blue-500 "
          >
            {t("payment-page.back-home")}
          </button>
        </div>
      </div>
      <div className="flex h-[68px] w-full items-center justify-center text-[14px] leading-[20px] text-gray-500 md:h-[72px] md:text-[16px] md:leading-[24px]">{t("payment-page.copy-right", {title: getAppTitle})}</div>
    </div>
  )
}
