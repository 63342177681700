//eslint-disable-next-line @typescript-eslint/no-explicit-any
/* eslint-disable indent */
import React from "react"
import Empty from "../../assets/images/empty.png"
import { useTranslation } from "react-i18next"

export default function PageNotFound() {
  const {t} = useTranslation()
  return (
    <div className="flex h-full w-full flex-col items-center justify-center rounded-3 bg-white py-2">
      <img
        src={Empty}
        alt="empty image"
        className="object-cover object-center"
      />
      <div className="text-16 font-medium text-gray-500">
        {t("page-not-found")}
      </div>
    </div>
  )
}
