import appLogoProduct from "src/assets/images/app-logo-blue.svg"
import appLogoDev from "src/assets/images/app-logo-dev.svg"
import whiteLogoAppProduct from "src/assets/images/app-logo-blue.svg"
import whiteLogoAppDev from "src/assets/images/app-logo-white-dev.svg"

export const isDevMode = () => {
  return process.env.REACT_APP_ENV === "DEVELOP"
}
export const getLogoApp = () => {
  if (isDevMode()) {
    return appLogoDev
  } else {
    return appLogoProduct
  }
}

export const getWhiteLogoApp = () => {
  if (isDevMode()) {
    return whiteLogoAppDev
  } else {
    return whiteLogoAppProduct
  }
}
