import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { signIn } from "src/features/auth"
import { RECEIVE_TOKEN_URL, STATE_RENDER_APP } from "src/constants/common"
import { ShareTokenTypes, isIFrame } from "./types"
import {
  MobileMessage,
  addMobileMessageListener,
  postMessageToMobile,
  removeMobileMessageListener,
  requestMobile,
} from "./mobile-message"
import i18n from "src/locales"

// let isIframeError = true
function ShareToken(props: ShareTokenTypes) {
  const { setStateRenderView } = props
  const [isRender, setIsRender] = useState<boolean>(true)
  const dispatch = useDispatch()

  useEffect(() => {
    window.addEventListener("message", handleReceiveMessage)
    requestMobile("accessToken").then(onTokenUpdatedFromMobile)
    updateLanguageFromMobileApp()
    addMobileMessageListener(onTokenUpdatedFromMobile) // TODO: remove in the future (but not now)
    postMessageToMobile("getAccessToken") // TODO: remove in the future (but not now)
    return () => {
      window.removeEventListener("message", handleReceiveMessage)
      removeMobileMessageListener(onTokenUpdatedFromMobile) // TODO: remove in the future (but not now)
    }
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleReceiveMessage = (e: any) => {
    if (e?.data?.type === "authorized") {
      const accessTokenData = e.data?.token?.accessToken
      const profileData = JSON.parse(e.data?.profile)
      // let data = undefined
      if (accessTokenData && profileData?.id) {
        // data = {
        //   user: profileData,
        //   access_token: accessTokenData,
        // }
        const lngResult = profileData?.language ? profileData?.language?.toLowerCase() : "en"
        i18n.changeLanguage(lngResult)
        // localStorage.setItem("accessToken", accessTokenData)
        // localStorage.setItem("userId", profileData?.id)
      }
      // isIframeError = false
      // dispatch(signIn(data))
      setStateRenderView(STATE_RENDER_APP.APP)
    } else if(e?.data?.type === "unauthorized") {
      i18n.changeLanguage("en")
      setStateRenderView(STATE_RENDER_APP.APP)
      updateLanguageFromMobileApp()
    }
  }

  const updateLanguageFromMobileApp = () => {
    requestMobile("language").then((message) =>
      i18n.changeLanguage(message.data ?? "en")
    )
    setStateRenderView(STATE_RENDER_APP.APP)
  }

  const onTokenUpdatedFromMobile = (message: MobileMessage) => {
    console.log("RECEIVE MOBILE MESS", message)
    if (message.type === "setAccessToken" || message.type === "accessToken") {
      const accessToken = message.data ?? ""
      localStorage.setItem("accessToken", accessToken)
      dispatch(signIn({
        user: undefined,
        access_token: accessToken,
      }))
      setStateRenderView(STATE_RENDER_APP.APP)
    }
  }

  const handleOnLoad = () => {
    const frame = document.getElementById("socialLogin")
    if (isIFrame(frame) && frame.contentWindow) {
      frame.contentWindow.postMessage({ type: "connect" }, "*")
    }
  }
  // const detectIframeError = () => {
  //   const detectResolve = () => {
  //     if (isIframeError) {
  //       dispatch(signIn(undefined))
  //       // setStateRenderView(STATE_RENDER_APP.APP)
  //     }
  //   }
  //   setTimeout(() => detectResolve(), 2000)
  // }

  useEffect(() => {
    // detectIframeError()
    setIsRender(true)
  }, [])

  if (!isRender) return null
  return (
    <iframe
      id="socialLogin"
      style={{
        width: 0,
        height: 0,
        position: "absolute",
        left: 0,
        top: 0,
        display: "none",
        visibility: "hidden",
      }}
      width={0}
      height={0}
      title="socialLogin"
      src={RECEIVE_TOKEN_URL}
      onLoad={handleOnLoad}
    />
  )
}

export default ShareToken
