import React, { useEffect, useState } from "react"
import stripeIcon from "src/assets/images/stripe-icon.svg"
import appotaIcon from "src/assets/images/appota-icon.svg"
import { getPaymentGateway } from "src/services/payment-service"
import { LoadingView } from "./loading-view"
import ImgCustom from "src/components/img-custom"
import { useTranslation } from "react-i18next"

interface PaymentTypeData {
  image: string
  name: string
  type: string
  header: string
  logo: string
}

const paymentMethod: PaymentTypeData[] = [
  {
    image: appotaIcon,
    name: "AppotaPay",
    type: "APP_OTA",
    header: "payment-page.vietnam-payment",
    logo: "",
  },
  {
    image: stripeIcon,
    name: "Stripe",
    type: "STRIPE",
    header: "payment-page.international-payment",
    logo: "",
  },
]

export interface OnlinePaymentType {
  updatePaymentType: (value: string) => void
  isClick: boolean
}
export default function OnlinePayment(props: OnlinePaymentType) {
  const { updatePaymentType, isClick } = props
  const [isRender, setIsRender] = useState<boolean>(false)
  const [paymentsType, setPaymentsType] = useState<PaymentTypeData[]>([])

  const {t} = useTranslation()
  const handleCLickPaymentType = (type: string) => {
    if(!isClick) updatePaymentType(type)
  }

  const detectPaymentType = () => {
    getPaymentGateway()
      .then((res) => {
        let isStripePayment = false
        let isAppOtaPayment = false
        let logoStripe = ""
        let logoAppOta = ""
        const result = [] as PaymentTypeData[]
        if (res?.data?.data?.length) {
          //eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.forEach((i: any) => {
            if (i?.name === "STRIPE") {
              isStripePayment = true
              logoStripe = i?.logo
            }
            if (i?.name === "APP_OTA") {
              logoAppOta = i?.logo
              isAppOtaPayment = true
            }
          })
        }
        paymentMethod.forEach((i) => {
          if (i.type === "STRIPE" && isStripePayment) {
            result.push({ ...i, ...{ logo: logoStripe } })
          }
          if (i.type === "APP_OTA" && isAppOtaPayment) {
            result.push({ ...i, ...{ logo: logoAppOta } })
          }
        })
        setPaymentsType(result)
      })
      .catch((err) => console.log(err))
      .finally(() => setIsRender(true))
  }

  useEffect(() => {
    detectPaymentType()
  }, [])

  if (!isRender) return <LoadingView />
  return (
    <div className="flex w-full flex-col p-[24px] relative">
      <div className="flex w-full items-center justify-center text-center text-[24px] font-semibold leading-[32px] text-blue-600 md:text-[36px] md:font-bold md:leading-[44px] ">
        {t("payment-page.online-title")}
      </div>
      <div className="mt-[24px]">
        <div className="flex grid grid-cols-1 gap-3">
          {paymentsType.map((i) => (
            <div
              onClick={() => handleCLickPaymentType(i.type)}
              key={i.type}
              className="flex cursor-pointer flex-col rounded-3 border border-gray-200  px-[16px] md:px-[24px] py-[20px] hover:border-blue-600"
            >
              <span className="text-center md:text-[22px] font-semibold md:leading-[26px] text-gray-900 text-[16px] leading-[20px]">
                {t(i.header)}
              </span>
              <div className="mt-[20px] flex flex-col rounded-3 border border-gray-200 px-[24px] py-[32px] items-center">
                <div className="h-[76px] w-[160px]">
                  <ImgCustom
                    url={i?.logo as string}
                    alt="paymentIcon"
                    className="h-full w-full object-contain"
                  />
                </div>
                {/* <img className="h-[76px]" alt={i.type} src={i.image} /> */}
                <span className="mt-[24px] text-center text-[14px] font-medium leading-[20px] text-gray-900 md:mt-[32px] md:text-[18px] md:leading-[28px]">
                  {i.name}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      {
        isClick ? (
          <div className="absolute top-[0px] left-[0px] w-full h-full">
            <LoadingView/>
          </div>
        ) : null
      }
    </div>
  )
}
