export const CHECKOUT_MODE = {
  CART: "CART",
  CHECKOUT: "CHECKOUT",
  PAYMENT: "PAYMENT",
  COMPLETE: "COMPLETE",
}

export const ORDER_STEP_VIEW = {
  CART: "CART",
  CHECKOUT: "CHECKOUT",
  PAYMENT: "PAYMENT",
  COMPLETE: "COMPLETE",
}
export const ORDER_TYPE_VIEW = {
  SHOP: "shop",
  FOOD: "food",
  BOOKING: "booking",
}

export const CART_TYPE = {
  PRODUCT: "PRODUCT",
  FOOD: "FOOD",
}
export const ADDRESS_TYPE = {
  HOME: "HOME",
  OFFICE: "OFFICE",
}

export const VOUCHER_TYPE = {
  VOUCHER: "VOUCHER",
  SHIPPING: "SHIPPING",
}
export const TYPE_VOUCHER_VIEW = {
  SHOP: "SHOP",
  TMT: "TMT",
}
export const DISCOUNT_TYPE = {
  PRICE: "PRICE",
  PERCENTAGE: "PERCENTAGE",
  COIN: "COIN",
}
export const VOUCHER_EXPIRED_TIME = 1890925151059
export const VOUCHER_EXPIRED_SOON_7_DAY = 604800000
export const PLATFORM_APPLY = ["WEB", "ALL"]

export const LAYOUT_TYPE = {
  IMAGE_WITH_TEXT: "IWT",
  BANNER: "BANNER",
  SLIDESHOW: "SINGLESLIDESHOW",
  MULTIPLE_SLIDESHOW: "MULTIPLESLIDESHOW",
  TITLE: "TITLE",
  PARAGRAPH: "PARAGRAPH",
  CATEGORY_LIST: "CATEGORYLIST",
  PRODUCT_LIST: "PRODUCTLIST",
}

export const ORDER_STATE = {
  FAILED: "FAILED",
  SUCCESS: "SUCCESS",
  WAIT_FOR_SHIP: "WAIT_FOR_SHIP",
  DELIVER_HANDEL: "DELIVER_HANDEL",
  DELIVER_ON_WAY: "DELIVER_ON_WAY",
  DELIVERED: "DELIVERED",
  COMPLETED: "COMPLETED",
  CANCELLED_BY_YOU: "CANCELLED_BY_YOU",
  CANCELLED_BY_SHOP: "CANCELLED_BY_SHOP",
  CANCELLED_BY_SYSTEM: "CANCELLED_BY_SYSTEM",
  REFUND: "REFUND",
  CONFIRMED: "CONFIRMED",
  UNPAID: "UNPAID",
}

export const PURCHASE_HISTORY_NAVIGATE_CODE = {
  PURCHASE: "PURCHASE",
  ADDRESS: "ADDRESS",
  VOUCHER: "VOUCHER",
  SETTING: "SETTING",
  SECURITY: "SECURITY",
  HELP: "HELP",
  BOOKMARK: "BOOKMARK",
}

export const PAYMENT_TYPE = {
  COD: "COD",
  BANKING: "BANK",
  CREDIT: "CARD",
  CRYPTO: "CRYPTO",
  QR: "QR",
  STRIPE: "STRIPE",
}
export const DISABLE_PAYMENT_TYPE = [
  PAYMENT_TYPE.CRYPTO,
  PAYMENT_TYPE.QR,
  PAYMENT_TYPE.BANKING,
]

export const BOOKMARK_CATEGORY = {
  PRODUCT: "PRODUCT_BOOKMARK",
  SHOP: "SHOP_BOOKMARK",
}

export const HOME_MOST = {
  MOST_POPULAR: "MOST_POPULAR",
  BEST_SELLER: "BEST_SALE",
  BEST_REVIEW: "BEST_REVIEWED",
}
export const TYPE_PAGE = {
  PRODUCT: "PRODUCT",
  FOOD: "FOOD",
}

export const IMAGE_TYPE = {
  WEB: "WEB",
  THUMBNAILS: "THUMBNAILS",
}

export const STATE_RENDER_APP = {
  INIT: "INIT",
  IFRAME: "IFRAME",
  APP: "APP",
}

export const SERVICE = {
  STOCK: "stock",
  CATALOG: "catalog",
  SHOP: "shop",
  STORAGE: "storage",
  FOOD: "food",
  ANGULAR_CATALOG: "catalog",
  RESTAURANT: "restaurant",
  PROMOTION: "promotion",
  PAYMENT: "payment",
  CART: "cart",
  RATING: "rating",
  SHIPMENT: "shipment",
  TRENDING: "trending",
  ADVERTISING: "advertising",
  ORDER: "order",
}

export const RECEIVE_TOKEN_URL = `${process.env.REACT_APP_HOME_DOMAIN_URL}/app-connect`

export const TYPELINK = {
  LINK: "link",
  DOMAIN_URL_YOUTUBE: "https://www.youtube.com/",
}
