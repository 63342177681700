import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { load as loadApi } from "src/services/load-service-example"

export interface LoadState {
  result: string
  loading?: "idle" | "pending" | "succeeded" | "failed"
}

const initialState: LoadState = {
  result: "N/A",
  loading: "idle",
}

export const load = createAsyncThunk("load/thunk", loadApi)

export const loadSlice = createSlice({
  name: "loadExample",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(load.fulfilled, (state, action) => {
      state.result = action.payload
      state.loading = "succeeded"
    })
    builder.addCase(load.pending, (state) => {
      state.result = "N//A"
      state.loading = "pending"
    })
    builder.addCase(load.rejected, (state) => {
      state.loading = "failed"
    })
  },
})

export default loadSlice.reducer
