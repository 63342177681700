import http from "src/config/interceptors"

export const getPaymentToken = (paymentToken: string) => {
  return http.get(`/payment/v2/payments/token/${paymentToken}`)
}

export const getPaymentType = (paymentToken: string) => {
  return http.get(`/payment/payment-type/${paymentToken}`)
}

export const charge = (paymentToken: string) => {
  return http.post(
    `/payment/payments/stripe/charge?payment_token=${paymentToken}`
  )
}
export const getPublicKey = () => {
  return http.get("/payment/payments/stripe/public-key")
}

export const gatewayCharge = (paymentToken: string, paymentType: string) => {
  return http.post(
    `/payment/payments/${paymentType}/charge?payment_token=${paymentToken}`
  )
}

export const getPaymentGateway = () => {
  return http.get("/payment/payment-gateway")
}
