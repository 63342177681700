import React, {  useEffect, useRef, useState } from "react"
import "./App.scss"
import MainLayout from "./components/layout/main-layout"
import { Navigate, useRoutes } from "react-router-dom"
import { Toast } from "primereact/toast"
import { ToastContext } from "./app/toast-context"
import { ROUTERS } from "./constants/router"
import OrderCart from "./pages/order-cart"
import PageNotFound from "./pages/404"
import PaymentComplete from "./pages/payment-complete"
import LandingView from "./pages/order-cart/landing-page"
import CardCheckout from "./pages/card-checkout"
import WelComePage from "./pages/welcome"
import AppOtaCheckout from "./pages/app-ota-checkout"
import PendingToken from "./pages/pending"
import { STATE_RENDER_APP } from "./constants/common"
import ShareToken from "./components/share-token"
import { Helmet, HelmetProvider } from "react-helmet-async"

function App() {
  const routes = useRoutes([
    {
      path: ROUTERS.ORDER_CART,
      element: <OrderCart />,
    },
    {
      path: ROUTERS.PAGE_NOT_FOUND,
      element: <PageNotFound />,
    },
    {
      path: ROUTERS.PAYMENT_COMPLETE,
      element: <PaymentComplete />,
    },
    {
      path: ROUTERS.HOME,
      element: <LandingView/>,
    },
    {
      path: ROUTERS.CARD_CHECKOUT,
      element: <CardCheckout/>,
    },
    {
      path: ROUTERS.OTA_CHECKOUT,
      element: <AppOtaCheckout/>,
    },
    {
      path: ROUTERS.WELCOME,
      element: <WelComePage/>,
    },
    {
      path: ROUTERS.PENDING,
      element: <PendingToken/>,
    },
    { path: "/", element: <Navigate to={ROUTERS.HOME} /> },
    { path: "*", element: <Navigate to={ROUTERS.PAGE_NOT_FOUND} /> },
  ])
  const toast = useRef<Toast>(null)
  const [stateRenderView, setStateRenderView] = useState<string>(STATE_RENDER_APP.INIT)
  const detectLogin = () => {
    setStateRenderView(STATE_RENDER_APP.IFRAME)
  }
  useEffect(() => {
    function initView() {
      detectLogin()
    }
    initView()
  }, [])
  if (stateRenderView === STATE_RENDER_APP.INIT) return null
  return (
    <HelmetProvider>
      <Helmet>
        <title>Soctrip payment gateway</title>
        <base href="/" />
        <meta
          name="description"
          content="Pay for your order on Soctrip"
        />
        <meta
          property="og:description"
          content="Pay for your order on Soctrip"
        />
        <meta
          name="twitter:description"
          content="Pay for your order on Soctrip"
        />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/soctrip-branding/soctrip-full.png"
        />
        <meta
          property="og:image:type"
          content="%PUBLIC_URL%/soctrip-branding/soctrip-full.png"
        />
        <meta
          property="og:image:secure_url"
          content="%PUBLIC_URL%/soctrip-branding/soctrip-full.png"
        />
        <meta
          name="twitter:image"
          content="%PUBLIC_URL%/soctrip-branding/soctrip-full.png"
        />
      </Helmet>
      <ToastContext.Provider value={toast}>
        <MainLayout onSearch={(keyword) => console.log("searching", keyword)}>
          {routes}
        </MainLayout>
        <ShareToken
          setStateRenderView={(newValue: string) => setStateRenderView(newValue)}
        />
        <Toast ref={toast} />
      </ToastContext.Provider>
    </HelmetProvider>
  )
}

export default App
